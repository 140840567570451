import { useEffect, useState } from 'react';
import Link from 'next/link';
import { Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { MdKeyboardArrowDown, MdLanguage } from 'react-icons/md';
import capitalizeFirstLetter from 'shared/utils/capitalizeFirstLetter';
import ConstructLogo from './ConstructLogo';
import { Dictionaries, Locale } from 'shared/i18n/i18n-config';
import { getI18nUtils } from 'shared/i18n/i18n-utils';
import { useParams } from 'next/navigation';

type Props = {
  availableLocales: { locale: string; pathname: string }[];
  dictionary: Partial<Dictionaries>;
};

function Footer({ availableLocales, dictionary }: Props): JSX.Element {
  const { formatMessage } = getI18nUtils(dictionary);
  const params = useParams<{ locale: Locale }>();
  const { locale }: { locale?: Locale } = params || {};
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const sortedJobFunctions = [
    'job-function-formworker',
    'job-function-woodworker',
    'job-function-driver',
    'job-function-roofer',
    'job-function-electrician',
    'job-function-mechanic',
  ];

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    setDropdownOpen(false);
  }, [locale]);

  return (
    <footer id="footer">
      <div className="pageFooterBottomWrapper">
        <Container className="pageFooterBottomContainer pt-5 text-center text-md-start text-lowercase">
          <Row className="d-flex align-items-center mb-2 mb-md-5">
            <Col
              xs="12"
              lg="3"
              className="d-flex justify-content-center justify-content-lg-start align-items-center flex-lg-row flex-column mb-2 mb-lg-0"
            >
              <Link href={`/${locale}`} className="text-decoration-none">
                <ConstructLogo textColor="#fff" width="50" height="50" />
              </Link>
              <h2 className="text-uppercase ms-0 ms-lg-3 pt-2">{formatMessage({ id: 'footer-slogan' })}</h2>
            </Col>
            <Col xs="5" className="divider-container">
              <hr className="footer-divider-top d-none d-lg-block" />
            </Col>
            <Col xs="12" lg={{ size: 1, order: 4 }} className="d-flex justify-content-center align-items-center mb-2 mb-lg-0">
              <div>
                <a className="text-decoration-none" href="https://linkedin.com/company/synergie-belgium">
                  <img src="/icons/Share_button_linkedin.svg" alt="linkedin share button" />
                </a>
              </div>
              <div className="ms-3">
                <a className="text-decoration-none" href="https://www.facebook.com/synergie.belgium/">
                  <img src="/icons/Share_button_Facebook.svg" alt="facebook share button" />
                </a>
              </div>
            </Col>
            <Col xs="12" lg={{ size: 1, order: 3 }}>
              <hr className="footer-divider" />
            </Col>
            <Col xs="12" lg={{ size: 2, order: 2 }} className="footer-language d-flex justify-content-center my-4">
              <Dropdown toggle={toggleDropdown} isOpen={dropdownOpen} className="d-flex justify-content-center">
                <DropdownToggle className="d-flex align-items-center justify-content-center">
                  <MdLanguage size={24} />
                  <div className="d-inline mx-2">{capitalizeFirstLetter(formatMessage({ id: `${locale}` }))}</div>
                  <MdKeyboardArrowDown size={24} />
                </DropdownToggle>
                <DropdownMenu end>
                  {availableLocales.length > 1 ? (
                    availableLocales
                      .filter(({ locale: al }) => al !== locale)
                      .map((l) => (
                        <Link
                          className="py-2 dropdown-item d-block text-capitalize"
                          href={`/${l.locale}${l.pathname}/`}
                          locale={l.locale}
                          key={`locale_switcher_${l.locale}`}
                        >
                          {formatMessage({ id: `${l.locale}` })}
                        </Link>
                      ))
                  ) : (
                    <Link
                      className="py-2 dropdown-item d-block text-capitalize"
                      href={locale === 'nl' ? '/fr/' : '/nl/'}
                      locale={locale === 'nl' ? 'fr' : 'nl'}
                    >
                      {locale === 'nl' ? formatMessage({ id: 'fr' }) : formatMessage({ id: 'nl' })}
                    </Link>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Row className="upper-row">
            {/* Over ons */}
            <Col xs="12" md="3">
              <h4 className="text-capitalize">{formatMessage({ id: 'pagenav-about-us' })}</h4>
              <ul>
                <li>
                  <Link className="text-decoration-none" href={`/${locale}/about/`}>
                    {formatMessage({ id: 'pagenav-about-us-about' })}
                  </Link>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.synergiejobs.be/${locale}/`}
                  >
                    {formatMessage({ id: 'pagenav-about-us-interim' })}
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.synergiecareers.be/${locale}/`}
                  >
                    {formatMessage({ id: 'pagenav-about-us-careers' })}
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.sandyou.be/${locale}/`}
                  >
                    {formatMessage({ id: 'pagenav-about-us-sandyou' })}
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={locale === 'fr' ? 'https://www.travaillerchezsynergie.be/fr/' : 'https://www.werkenbijsynergie.be/nl/'}
                  >
                    {formatMessage({ id: 'pagenav-employees-work-at-synergie' })}
                  </a>
                </li>
              </ul>
            </Col>
            {/* werknemers */}
            <Col xs="12" md="3">
              <h4 className="text-capitalize">{formatMessage({ id: 'pagenav-employees' })}</h4>
              <ul>
                <li>
                  <Link className="text-decoration-none" href={`/${locale}/candidates/why/`}>
                    {formatMessage({ id: 'pagenav-employees-candidateswhy' })}
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" href={`/${locale}/apply/`}>
                    {formatMessage({ id: 'pagenav-employees-apply' })}
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" href={`/${locale}/companies/`}>
                    {formatMessage({ id: 'pagenav-employees-companies' })}
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" href={`/${locale}/blog/`}>
                    {formatMessage({ id: 'pagenav-blog' })}
                  </Link>
                </li>
              </ul>
            </Col>
            {/* werkgevers */}
            <Col xs="12" md="3">
              <h4 className="text-capitalize">{formatMessage({ id: 'pagenav-employers' })}</h4>
              <ul>
                <li>
                  <Link className="text-decoration-none" href={`/${locale}/employers/why/`}>
                    {formatMessage({ id: 'pagenav-employers-employerswhy' })}
                  </Link>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    href={`/${locale}/employers/contact/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage({ id: 'pagenav-employers-contact' })}
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    href={`https://www2.synergiejobs.be/${locale}/business/synergie-online?utm_source=synergieconstruct.be&utm_content=footer`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage({ id: 'pagenav-employees-online' })}
                  </a>
                </li>
                <li>
                  <a className="text-decoration-none" href={`/${locale}/dimona/`} target="_blank" rel="noopener noreferrer">
                    {formatMessage({ id: 'dimona-title' })}
                  </a>
                </li>
              </ul>
            </Col>
            {/* functies */}
            <Col xs="12" md="3">
              <h4 className="text-capitalize">{formatMessage({ id: 'pagenav-jobFunctions' })}</h4>
              <ul>
                {sortedJobFunctions &&
                  sortedJobFunctions.map((jobFunction) => (
                    <li key={jobFunction}>
                      <Link className="text-decoration-none" href={`/${locale}/jobs?what=${formatMessage({ id: jobFunction })}`}>
                        {formatMessage({ id: jobFunction })}
                      </Link>
                    </li>
                  ))}
              </ul>
            </Col>
          </Row>
          <hr className="footer-divider" />
          <ul
            className="d-flex flex-column flex-md-row justify-content-center justify-content-lg-start align-items-center flex-wrap ps-0 pt-3 pb-5 mb-0"
            id="footer-legals"
          >
            <li className="d-inline-block me-4 py-2">
              <Link className="text-decoration-none" href={`/${locale}/privacy/`}>
                {formatMessage({ id: 'footer-privacy-cookies' })}
              </Link>
            </li>
            <li className="d-inline-block me-4 py-2">
              <Link className="text-decoration-none" href={`/${locale}/termsofuse/`}>
                {formatMessage({ id: 'termsofuse' })}
              </Link>
            </li>
            <li className="d-inline-block me-4 py-2">
              <Link className="text-decoration-none" href={`/${locale}/termsandconditions/`}>
                {formatMessage({ id: 'termsandconditions' })}
              </Link>
            </li>
            <li className="d-inline-block me-4 py-2">
              <Link className="text-decoration-none" href={`/${locale}/registrationnumbers/`}>
                {formatMessage({ id: 'footer-registration-numbers' })}
              </Link>
            </li>
            <li className="d-inline-block me-4 py-2">
              <a className="text-decoration-none" href={`https://synergie.integrityline.com${locale && `?lang=${locale}`}`}>
                {formatMessage({ id: 'footer-integrity-platform' })}
              </a>
            </li>
            <li className="d-inline-block me-4 py-2">
              <Link className="text-decoration-none" href={`/${locale}/codeofconduct/`}>
                {formatMessage({ id: 'footer-ethical-code' })}
              </Link>
            </li>
            <li className="d-inline-block me-4 py-2">
              <Link className="text-decoration-none" href={`/${locale}/rights-request/`}>
                {formatMessage({ id: 'footer-gdpr-form' })}
              </Link>
            </li>
          </ul>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
