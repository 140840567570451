import { useState, useEffect } from 'react';
import { useParams, usePathname } from 'next/navigation';
import Link from 'next/link';
import { DropdownMenu, DropdownToggle, Nav, NavItem, Dropdown } from 'reactstrap';
import { MdClear, MdKeyboardArrowDown, MdMenu } from 'react-icons/md';
import useWindowWidth from 'shared/hooks/useWindowWidth';
import capitalizeFirstLetter from 'shared/utils/capitalizeFirstLetter';
import { plausibleEvent } from 'shared/utils/plausibleEvent';
import LanguageDropdown from 'shared/components/nav/LanguageDropdown';
import MySynergieDropdown from 'shared/components/nav/MySynergieDropdown';
import Avatar from 'shared/components/mySynergie/Avatar';
import ConstructLogo from './ConstructLogo';
import { Dictionaries, Locale } from 'shared/i18n/i18n-config';
import { getI18nUtils } from 'shared/i18n/i18n-utils';

function Navigation({
  availableLocales,
  whiteLogoText = false,
  bgLight,
  dictionary,
}: {
  availableLocales: { locale: string; pathname: string }[];
  bgLight?: boolean;
  whiteLogoText?: boolean;
  dictionary: Partial<Dictionaries>;
}): JSX.Element {
  const params = useParams();
  const { formatMessage } = getI18nUtils(dictionary);
  const { locale }: { locale?: Locale } = params || {};
  const pathname = usePathname();
  const windowWidth = useWindowWidth();
  const onMobile = windowWidth && windowWidth < 992;
  const [navmenuOpen, setNavmenuOpen] = useState(false);
  const [employeesDropdownOpen, setEmployeesDropdownOpen] = useState(false);
  const [employersDropdownOpen, setEmployersDropdownOpen] = useState(false);

  const toggleNavmenu = () => {
    setNavmenuOpen(!navmenuOpen);
    // Prevent navmenu to 'remember' its scroll position
    const mainNav = document.getElementById('mainNav');
    mainNav?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleEmployeesDropdown = () => setEmployeesDropdownOpen(!employeesDropdownOpen);
  const toggleEmployersDropdown = () => setEmployersDropdownOpen(!employersDropdownOpen);

  function addPlausibleEvent(e) {
    const path = e.target.pathname || e.target.href;
    if (path) plausibleEvent('navItemClick', { path });
  }

  // When mobile dropdown is open MySynergie avatar & candidate info are hidden
  // When screenwidth changes dropdown becomes invisible and should be closed so MySynergie component is visible again
  // Also, dropdown needs to be set to 'closed' state again when language changes.
  useEffect(() => {
    setNavmenuOpen(false);
  }, [onMobile, locale]);

  // When the mobile menu is opened, prevent scrolling on the page behind
  useEffect(() => {
    if (navmenuOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [navmenuOpen]);

  const dropdownEmployeeState =
    pathname === `/${locale}/apply/` ||
    pathname === `/${locale}/companies/` ||
    pathname === `/${locale}/blog/` ||
    pathname === `/${locale}/events/` ||
    pathname === `/${locale}/faq/` ||
    pathname === `/${locale}/candidates/why/`
      ? 'active'
      : 'inactive';

  const dropdownEmployerState =
    pathname === `/${locale}/employers/why` || pathname === `/${locale}/employers/contact` || pathname === `/${locale}/dimona`
      ? 'active'
      : 'inactive';

  const navItemAboutState = pathname === `/${locale}/about` ? 'active' : 'inactive';
  const navItemJobsState = pathname === `/${locale}/jobs` ? 'active' : 'inactive';
  const navItemAgenciesState = pathname === `/${locale}/agencies` ? 'active' : 'inactive';

  // const darkLogoText =
  //   pathname === '/' ||
  //   pathname === '/employers/why' ||
  //   pathname === '/candidates/why' ||
  //   pathname === '/faq/pay' ||
  //   pathname === '/faq/card' ||
  //   pathname === '/faq/leave' ||
  //   pathname === '/faq/safety' ||
  //   pathname === '/faq/weather' ||
  //   pathname === '/faq/stamps' ||
  //   darkText;

  return (
    <div className={`p-0 navbar navbar-expand-lg ${bgLight ? 'bg-light' : ''}`}>
      <div className="container-lg">
        {/* Logo */}
        <Link className="navbar-logo" href={`/${locale}/`} locale={locale} onClick={(e) => addPlausibleEvent(e)}>
          <ConstructLogo textColor={whiteLogoText ? '#fff' : '#000'} className="navLogo" />
        </Link>

        {/* Mobile hamburger menu button */}
        <div className="d-flex align-items-center d-lg-none">
          {!navmenuOpen && (
            <Link href={`/${locale}/my-synergie`}>
              <Avatar dictionary={dictionary} />
            </Link>
          )}
          <div
            className={`navbar-menubutton py-3 ps-3 ${navmenuOpen ? 'open' : ''}`}
            onClick={toggleNavmenu}
            onKeyDown={toggleNavmenu}
            role="button"
            tabIndex={0}
          >
            {navmenuOpen ? <MdClear size={24} /> : <MdMenu size={24} />}
          </div>
        </div>

        {/* navigation */}
        <div
          className={`navbar-navigation flex-grow-1 ${navmenuOpen ? 'show' : ''} ${bgLight && !navmenuOpen ? 'bg-light' : ''}`}
        >
          <Nav navbar className="ms-0 ms-lg-4 px-3 px-lg-0" id="mainNav">
            {/* Jobs */}
            <NavItem className="me-2">
              <Link
                className={`${navItemJobsState} nav-link d-flex align-items-center text-secondary`}
                href={`/${locale}/jobs/`}
                onClick={(e) => addPlausibleEvent(e)}
                locale={locale}
              >
                {capitalizeFirstLetter(formatMessage({ id: 'pagenav-jobs' }))}
              </Link>
            </NavItem>
            {/* Agencies */}
            <NavItem className="me-2">
              <Link
                className={`${navItemAgenciesState} nav-link d-flex align-items-center text-secondary`}
                href={`/${locale}/agencies/`}
                onClick={(e) => addPlausibleEvent(e)}
                locale={locale}
              >
                {capitalizeFirstLetter(formatMessage({ id: 'pagenav-agencies' }))}
              </Link>
            </NavItem>
            {/* Employees */}
            <Dropdown
              toggle={toggleEmployeesDropdown}
              onMouseEnter={() => setEmployeesDropdownOpen(true)}
              onMouseLeave={() => setEmployeesDropdownOpen(false)}
              isOpen={employeesDropdownOpen}
              nav
              inNavbar
              className="mainDropdown"
            >
              <DropdownToggle nav className={`${dropdownEmployeeState} text-secondary me-2 d-flex flex-row align-items-center`}>
                {capitalizeFirstLetter(formatMessage({ id: 'pagenav-employees' }))}
                {!onMobile && <MdKeyboardArrowDown size={20} />}
              </DropdownToggle>
              <DropdownMenu className="mainMenu alwaysOpenMobile">
                <Link
                  className="dropdown-item my-0 py-2 px-3"
                  href={`/${locale}/candidates/why/`}
                  onClick={(e) => addPlausibleEvent(e)}
                  locale={locale}
                >
                  {capitalizeFirstLetter(formatMessage({ id: 'pagenav-employees-candidateswhy' }))}
                </Link>
                <Link
                  className="dropdown-item my-0 py-2 px-3"
                  href={`/${locale}/apply/`}
                  onClick={(e) => addPlausibleEvent(e)}
                  locale={locale}
                >
                  {capitalizeFirstLetter(formatMessage({ id: 'pagenav-employees-apply' }))}
                </Link>
                <Link
                  className="dropdown-item my-0 py-2 px-3"
                  href={`/${locale}/companies/`}
                  onClick={(e) => addPlausibleEvent(e)}
                  locale={locale}
                >
                  {capitalizeFirstLetter(formatMessage({ id: 'pagenav-employees-companies' }))}
                </Link>
                <Link
                  className="dropdown-item my-0 py-2 px-3"
                  href={`/${locale}/blog/`}
                  onClick={(e) => addPlausibleEvent(e)}
                  locale={locale}
                >
                  {capitalizeFirstLetter(formatMessage({ id: 'pagenav-blog' }))}
                </Link>
                <Link
                  className="dropdown-item my-0 py-2 px-3"
                  href={`/${locale}/events/`}
                  onClick={(e) => addPlausibleEvent(e)}
                  locale={locale}
                >
                  {capitalizeFirstLetter(formatMessage({ id: 'pagenav-employees-events' }))}
                </Link>
                <Link className="dropdown-item" href={`/${locale}/faq/`} locale={locale} onClick={(e) => addPlausibleEvent(e)}>
                  {formatMessage({ id: 'pagenav-employees-faq' })}
                </Link>
              </DropdownMenu>
            </Dropdown>
            {/* Employers */}
            <Dropdown
              toggle={toggleEmployersDropdown}
              onMouseEnter={() => setEmployersDropdownOpen(true)}
              onMouseLeave={() => setEmployersDropdownOpen(false)}
              isOpen={employersDropdownOpen}
              nav
              inNavbar
              className="mainDropdown"
            >
              <DropdownToggle nav className={`${dropdownEmployerState} text-secondary me-2 d-flex flex-row align-items-center`}>
                {capitalizeFirstLetter(formatMessage({ id: 'pagenav-employers' }))}
                {!onMobile && <MdKeyboardArrowDown size={20} />}
              </DropdownToggle>
              <DropdownMenu className="mainMenu alwaysOpenMobile">
                <Link
                  className="dropdown-item my-0 py-2 px-3"
                  href={`/${locale}/employers/why/`}
                  onClick={(e) => addPlausibleEvent(e)}
                  locale={locale}
                >
                  {capitalizeFirstLetter(formatMessage({ id: 'pagenav-employers-employerswhy' }))}
                </Link>
                <Link
                  className="dropdown-item my-0 py-2 px-3"
                  href={`/${locale}/employers/contact/`}
                  onClick={(e) => addPlausibleEvent(e)}
                  locale={locale}
                >
                  {capitalizeFirstLetter(formatMessage({ id: 'pagenav-employers-contact' }))}
                </Link>
                <a
                  href={`https://www2.synergiejobs.be/${locale}/business/synergie-online?utm_source=synergieconstruct.be&utm_content=navigation`}
                  onClick={(e) => addPlausibleEvent(e)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dropdown-item my-0 py-2 px-3"
                >
                  {capitalizeFirstLetter(formatMessage({ id: 'pagenav-employees-online' }))}
                </a>
                <Link
                  className="dropdown-item my-0 py-2 px-3"
                  href={`/${locale}/dimona/`}
                  onClick={(e) => addPlausibleEvent(e)}
                  locale={locale}
                >
                  {capitalizeFirstLetter(formatMessage({ id: 'dimona-title' }))}
                </Link>
              </DropdownMenu>
            </Dropdown>
            {/* About */}
            <NavItem style={{ whiteSpace: 'nowrap' }}>
              <Link
                className={`${navItemAboutState} nav-link d-flex align-items-center text-secondary`}
                href={`/${locale}/about/`}
                onClick={(e) => addPlausibleEvent(e)}
                locale={locale}
              >
                {capitalizeFirstLetter(formatMessage({ id: 'pagenav-about' }))}
              </Link>
            </NavItem>
            <div className="ms-lg-auto d-lg-flex justify-content-end">
              {/* MySynergie */}
              <MySynergieDropdown dictionary={dictionary} />
              {/* Language */}
              <LanguageDropdown dictionary={dictionary} availableLocales={availableLocales} />
            </div>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
