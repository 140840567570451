'use client';

import { PropsWithChildren } from 'react';
import PageHeader, { PageHeaderType } from 'shared/components/pageHeader/Index';
import { NProgressBar } from 'shared/components/NProgressBar';
import Navbar from './Navbar';
import Footer from './Footer';
import { Dictionaries } from 'shared/i18n/i18n-config';
import { usePathname } from 'next/navigation';

type PageLocale = { locale: string; pathname: string };

const defaultLocales: PageLocale[] = [
  { locale: 'nl', pathname: '/' },
  { locale: 'fr', pathname: '/' },
];

const AppLayout: React.FC<
  PropsWithChildren<{
    className?: string;
    greyBg?: boolean;
    id?: string;
    availableLocales?: PageLocale[];
    noSpacer?: boolean;
    pageHeader?: PageHeaderType;
    bgLight?: boolean;
    dictionary: Partial<Dictionaries>;
  }>
> = ({
  children,
  className,
  id,
  noSpacer,
  greyBg,
  availableLocales = defaultLocales,
  pageHeader,
  bgLight = false,
  dictionary,
}) => {
  const pathname = usePathname();
  return (
    <div className={className} id={id || ''}>
      <NProgressBar pathname={pathname} />
      <Navbar
        dictionary={dictionary}
        availableLocales={availableLocales}
        whiteLogoText={!noSpacer || (pageHeader && pageHeader.darkened)}
        bgLight={bgLight}
      />
      {!noSpacer && !greyBg && <div id="navSpacer" />}
      {pageHeader && <PageHeader dictionary={dictionary} pageHeader={pageHeader} />}
      <main style={{ minHeight: '55vh' }}>{children}</main>
      <Footer dictionary={dictionary} availableLocales={availableLocales} />
    </div>
  );
};

export default AppLayout;
