import React from 'react';

function ConstructLogo({
  textColor,
  width,
  height,
  className,
}: {
  textColor?: string;
  width?: string;
  height?: string;
  className?: string;
}): JSX.Element {
  return (
    <>
      <svg
        height={height || '60'}
        viewBox="0 0 130 60"
        xmlns="http://www.w3.org/2000/svg"
        className={`logo d-lg-none${className ? ` ${className}` : ''}`}
        id="mobileLogo"
      >
        <g fill="none">
          <path d="m60 59.9789474v-59.9789474h-60v60z" fill="#e30613" />
          <g transform="translate(2 5)">
            <path d="m34.0226415 12.85625v-12.1125h-12.045283v12.1125z" fill="#fff" />
            <g fill="#fff" transform="translate(.602264 38.898125)">
              <path d="m4.76528302 4.7175-1.56377359-.68c-.85584905-.371875-1.03547169-.74375-1.03547169-1.370625 0-.595.24301886-1.16875 1.15169811-1.16875.77132075 0 1.09886792.5525 1.09886792 1.498125h1.94415095v-.286875c0-1.93375-1.31018868-2.709375-3.1064151-2.709375-1.91245283 0-3.10641509.99875-3.10641509 2.964375 0 1.67875.82415094 2.433125 2.63094339 3.049375 1.33132076.4675 1.79622642.8075 1.79622642 1.72125 0 .85-.59169811 1.243125-1.2045283 1.243125-1.06716981 0-1.33132076-.605625-1.33132076-1.55125v-.2975h-2.03924528v.414375c0 1.87.89811321 2.943125 3.1909434 2.943125 2.18716981 0 3.48679245-.95625 3.48679245-3.091875 0-1.2325-.40150943-2.01875-1.91245283-2.6775z" />
              <path d="m10.650566 4.090625h-.021132l-1.32075475-3.899375h-2.28226416l2.59924529 6.12v3.97375h2.02867922v-3.97375l2.5992453-6.12h-2.1977358z" />
              <path d="m19.8007547 7.108125h-.0211321l-2.4513207-6.916875h-2.3350944v10.09375h1.8596227v-7.065625h.0211321l2.4830188 7.065625h2.3033963v-10.09375h-1.8596227z" />
              <path d="m25.0837736 5.9075h3.3811321v-1.668125h-3.3811321v-2.38h3.5924528v-1.668125h-5.610566v10.09375h5.7479245v-1.668125h-3.7298113z" />
              <path d="m32.6913208 4.600625h-.8452831v-2.91125h.9509434c.9086793 0 1.4052831.4675 1.4052831 1.4025.010566 1.08375-.538868 1.50875-1.5109434 1.50875zm3.4867924 3.793125c-.0316981-2.135625-.2430189-2.900625-1.6166038-3.08125v-.031875c1.1411321-.244375 1.669434-1.1475 1.669434-2.48625 0-1.72125-1.0143396-2.603125-2.6732076-2.603125h-3.7298113v10.09375h2.0286793v-4.27125h1.0777358c.8135849 0 1.1622642.478125 1.1939623 1.275l.0528302 1.93375c.010566.393125.0528302.775625.2535849 1.0625h2.1871698v-.085c-.3698113-.201875-.4332076-.74375-.4437736-1.80625z" />
              <path d="m40.869434 6.343125h1.3313207v1.030625c0 1.08375-.7713207 1.615-1.2467924 1.615-1.1622642 0-1.394717-1.53-1.394717-3.9525 0-2.518125.3909434-3.538125 1.3735849-3.538125.8135849 0 1.1516981.945625 1.1516981 1.7h1.9441509c0-2.050625-.8981132-3.198125-3.1169811-3.198125-2.5886792 0-3.370566 1.7425-3.370566 5.131875 0 3.495625.4966037 5.355 2.9796226 5.355.9403774 0 1.6271698-.34 2.1132076-1.12625h.0316981v.935h1.4898113v-5.44h-3.2754717v1.4875z" />
              <path d="m45.5501887 10.285h2.0286792v-10.09375h-2.0286792z" />
              <path d="m51.1079245 8.616875v-2.709375h3.3705661v-1.668125h-3.3705661v-2.38h3.5818868v-1.668125h-5.610566v10.09375h5.7479245v-1.668125z" />
            </g>
            <g fill="#303337" transform="translate(66 20)">
              <path d="m6.1772093 8.645c-.15906977 1.911-1.21953488 3.172-2.91627907 3.172-1.93534883 0-3.06209302-1.313-3.06209302-4.693 0-3.107 1.14-4.68 3.15488372-4.68 1.72325581 0 2.71744186 1.027 2.82348837 2.873h-1.03395349c-.11930232-1.404-.68930232-2.028-1.78953488-2.028-1.12674419 0-2.05465116.676-2.05465116 3.848 0 3.38.98093023 3.835 1.94860465 3.835 1.0472093 0 1.72325581-.754 1.89558139-2.327z" />
              <path d="m10.3660465 2.444c2.0148837 0 3.1548837 1.573 3.1548837 4.68 0 3.133-1.14 4.693-3.1548837 4.693-2.01488371 0-3.15488371-1.573-3.15488371-4.693 0-3.107 1.14-4.68 3.15488371-4.68zm0 8.528c1.1532558 0 2.0546512-.975 2.0546512-3.848 0-2.86-.9013954-3.835-2.0546512-3.835s-2.05465115.975-2.05465115 3.835c0 2.873.90139535 3.848 2.05465115 3.848z" />
              <path d="m15.032093 2.639h.927907v1.261h.0397674c.5169768-1.001 1.4183721-1.456 2.3462791-1.456 1.3918605 0 2.3462791.715 2.3462791 2.405v6.773h-1.0339535v-6.487c0-1.235-.5169768-1.846-1.5641861-1.846-1.206279 0-2.0148837.91-2.0148837 2.301v6.032h-1.0339535v-8.983z" />
              <path d="m24.1255814 7.15c-1.285814-.494-1.7630233-1.235-1.7630233-2.132 0-1.846 1.1665117-2.587 2.7174419-2.587 1.7232558 0 2.5186047.858 2.5186047 2.496v.273h-1.0339535v-.26c0-1.17-.5567442-1.651-1.497907-1.651-1.2062791 0-1.6702326.624-1.6702326 1.521 0 .624.2120931 1.118 1.2460465 1.508l1.497907.559c1.2460465.455 1.71 1.3 1.71 2.275 0 1.534-.9013953 2.665-2.8234884 2.665-1.8823255 0-2.8102325-.754-2.8102325-2.795v-.286h1.0339535v.234c0 1.313.5832558 1.989 1.7630232 1.989 1.1002326 0 1.8160465-.611 1.8160465-1.612 0-.793-.3313953-1.3-1.2460465-1.651z" />
              <path d="m29.7593023 3.484h-1.2593023v-.845h1.2593023v-2.483h1.0339535v2.483h1.6172093v.845h-1.6172093v6.435c0 .715.3048837.949.9809302.949.2120931 0 .4109303-.039.6362791-.104v.845c-.1193023.065-.3844186.104-.7290698.104-1.3918604 0-1.9353488-.377-1.9353488-1.573v-6.656z" />
              <path d="m33.5372093 2.639h1.0339535v1.404h.0397674c.3976745-1.001 1.2460465-1.612 2.3065117-1.612.1193023 0 .2386046.013.3579069.052v1.014c-.1723255-.039-.3446511-.052-.5037209-.052-1.14 0-2.2004651.806-2.2004651 2.548v5.616h-1.0339535z" />
              <path d="m38.3225581 2.639h1.0339535v6.318c0 1.456.503721 2.015 1.5906977 2.015 1.1665116 0 2.0016279-.91 2.0016279-2.301v-6.032h1.0339535v8.97h-.927907v-1.248h-.0397674c-.5169768 1.001-1.4183721 1.456-2.3462791 1.456-1.3918605 0-2.3462791-.715-2.3462791-2.405z" />
              <path d="m51.4723256 8.645c-.1590698 1.911-1.2195349 3.172-2.9162791 3.172-1.9353488 0-3.062093-1.313-3.062093-4.693 0-3.107 1.14-4.68 3.1548837-4.68 1.7232558 0 2.7174419 1.027 2.8234884 2.873h-1.0339535c-.1193023-1.404-.6893023-2.028-1.7895349-2.028-1.1267442 0-2.0546512.676-2.0546512 3.848 0 3.38.9809303 3.835 1.9486047 3.835 1.0472093 0 1.7232558-.754 1.8955814-2.327z" />
              <path d="m53.367907 3.484h-1.2593023v-.845h1.2593023v-2.483h1.0339535v2.483h1.6172093v.845h-1.6172093v6.435c0 .715.3048837.949.9809302.949.212093 0 .4109302-.039.6362791-.104v.845c-.1193024.065-.3844186.104-.7290698.104-1.3918605 0-1.9353488-.377-1.9353488-1.573v-6.656z" />
            </g>
          </g>
        </g>
      </svg>
      <svg
        enableBackground="new 0 0 56.69 70.87"
        viewBox="0 0 56.69 70.87"
        xmlns="http://www.w3.org/2000/svg"
        height={height || '60'}
        width={width || '60'}
        className={`logo d-none d-lg-block ${className ? ` ${className}` : ''}`}
      >
        <path d="m57 56.98v-56.98h-57v57z" fill="#e30613" />
        <g fill="#fff">
          <path d="m34.2 17.1v-11.4h-11.4v11.4z" />
          <path d="m7.08 46.05-1.48-.64c-.81-.35-.98-.7-.98-1.29 0-.56.23-1.1 1.09-1.1.73 0 1.04.52 1.04 1.41h1.84v-.27c0-1.82-1.24-2.55-2.94-2.55-1.81 0-2.94.94-2.94 2.79 0 1.58.78 2.29 2.49 2.87 1.26.44 1.7.76 1.7 1.62 0 .8-.56 1.17-1.14 1.17-1.01 0-1.26-.57-1.26-1.46v-.28h-1.93v.39c0 1.76.85 2.77 3.02 2.77 2.07 0 3.3-.9 3.3-2.91 0-1.16-.38-1.9-1.81-2.52z" />
          <path d="m12.65 45.46h-.02l-1.25-3.67h-2.16l2.46 5.76v3.74h1.92v-3.74l2.46-5.76h-2.08z" />
          <path d="m21.31 48.3h-.02l-2.32-6.51h-2.21v9.5h1.76v-6.65h.02l2.35 6.65h2.18v-9.5h-1.76z" />
          <path d="m26.31 47.17h3.2v-1.57h-3.2v-2.24h3.4v-1.57h-5.31v9.5h5.44v-1.57h-3.53z" />
          <path d="m33.51 45.94h-.8v-2.74h.9c.86 0 1.33.44 1.33 1.32.01 1.02-.51 1.42-1.43 1.42zm3.3 3.57c-.03-2.01-.23-2.73-1.53-2.9v-.03c1.08-.23 1.58-1.08 1.58-2.34 0-1.62-.96-2.45-2.53-2.45h-3.53v9.5h1.92v-4.02h1.02c.77 0 1.1.45 1.13 1.2l.05 1.82c.01.37.05.73.24 1h2.07v-.08c-.35-.19-.41-.7-.42-1.7z" />
          <path d="m41.25 47.58h1.26v.97c0 1.02-.73 1.52-1.18 1.52-1.1 0-1.32-1.44-1.32-3.72 0-2.37.37-3.33 1.3-3.33.77 0 1.09.89 1.09 1.6h1.84c0-1.93-.85-3.01-2.95-3.01-2.45 0-3.19 1.64-3.19 4.83 0 3.29.47 5.04 2.82 5.04.89 0 1.54-.32 2-1.06h.03v.88h1.41v-5.12h-3.1v1.4z" />
          <path d="m45.68 51.29h1.92v-9.5h-1.92z" />
          <path d="m50.94 49.72v-2.55h3.19v-1.57h-3.19v-2.24h3.39v-1.57h-5.31v9.5h5.44v-1.57z" />
        </g>
        <g fill={textColor || '#000'}>
          <path d="m16.66 66.65c-.12 1.47-.92 2.44-2.2 2.44-1.46 0-2.31-1.01-2.31-3.61 0-2.39.86-3.6 2.38-3.6 1.3 0 2.05.79 2.13 2.21h-.78c-.09-1.08-.52-1.56-1.35-1.56-.85 0-1.55.52-1.55 2.96 0 2.6.74 2.95 1.47 2.95.79 0 1.3-.58 1.43-1.79z" />
          <path d="m19.82 61.88c1.52 0 2.38 1.21 2.38 3.6 0 2.41-.86 3.61-2.38 3.61s-2.38-1.21-2.38-3.61c0-2.39.86-3.6 2.38-3.6zm0 6.56c.87 0 1.55-.75 1.55-2.96 0-2.2-.68-2.95-1.55-2.95s-1.55.75-1.55 2.95c0 2.21.68 2.96 1.55 2.96z" />
          <path d="m23.34 62.03h.7v.97h.03c.39-.77 1.07-1.12 1.77-1.12 1.05 0 1.77.55 1.77 1.85v5.21h-.78v-4.99c0-.95-.39-1.42-1.18-1.42-.91 0-1.52.7-1.52 1.77v4.64h-.78v-6.91z" />
          <path d="m30.2 65.5c-.97-.38-1.33-.95-1.33-1.64 0-1.42.88-1.99 2.05-1.99 1.3 0 1.9.66 1.9 1.92v.21h-.78v-.2c0-.9-.42-1.27-1.13-1.27-.91 0-1.26.48-1.26 1.17 0 .48.16.86.94 1.16l1.13.43c.94.35 1.29 1 1.29 1.75 0 1.18-.68 2.05-2.13 2.05-1.42 0-2.12-.58-2.12-2.15v-.22h.78v.18c0 1.01.44 1.53 1.33 1.53.83 0 1.37-.47 1.37-1.24 0-.61-.25-1-.94-1.27z" />
          <path d="m34.45 62.68h-.95v-.65h.95v-1.91h.78v1.91h1.22v.65h-1.22v4.95c0 .55.23.73.74.73.16 0 .31-.03.48-.08v.65c-.09.05-.29.08-.55.08-1.05 0-1.46-.29-1.46-1.21v-5.12z" />
          <path d="m37.3 62.03h.78v1.08h.03c.3-.77.94-1.24 1.74-1.24.09 0 .18.01.27.04v.78c-.13-.03-.26-.04-.38-.04-.86 0-1.66.62-1.66 1.96v4.32h-.78z" />
          <path d="m40.91 62.03h.78v4.86c0 1.12.38 1.55 1.2 1.55.88 0 1.51-.7 1.51-1.77v-4.64h.78v6.9h-.7v-.96h-.03c-.39.77-1.07 1.12-1.77 1.12-1.05 0-1.77-.55-1.77-1.85z" />
          <path d="m50.83 66.65c-.12 1.47-.92 2.44-2.2 2.44-1.46 0-2.31-1.01-2.31-3.61 0-2.39.86-3.6 2.38-3.6 1.3 0 2.05.79 2.13 2.21h-.78c-.09-1.08-.52-1.56-1.35-1.56-.85 0-1.55.52-1.55 2.96 0 2.6.74 2.95 1.47 2.95.79 0 1.3-.58 1.43-1.79z" />
          <path d="m52.26 62.68h-.95v-.65h.95v-1.91h.78v1.91h1.22v.65h-1.22v4.95c0 .55.23.73.74.73.16 0 .31-.03.48-.08v.65c-.09.05-.29.08-.55.08-1.05 0-1.46-.29-1.46-1.21v-5.12z" />
        </g>
      </svg>
    </>
  );
}

export default ConstructLogo;
